import React from "react";
import { Link } from "@reach/router"
import { 
  Box, 
  Flex, 
  Text, 
  Button,
  Image,
} from "@chakra-ui/react";
import Logo from "../ui/Logo";
import abcImgName from "../../images/abc.svg"
import githubImgName from "../../images/github.svg"

const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <path
      fill="white"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="black"
  >
    B<title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const MenuItem = ({ children }) => (
  <Text mt={{ base: 4, md: 0 }} mr={6} display="block" fontWeight="bold">
    {children}
  </Text>
);

const Header = (props) => {
  const [show, setShow] = React.useState(false);
  const toggleMenu = () => setShow(!show);

  return (
    <Flex minH={'60px'} align={'center'} w='100%'>
      <Flex flex={1} justify={'start'} ml={10}>
        <a href="./">
          <Image src={abcImgName} w="30px" h="30px"/>
        </a>
      </Flex>
      <Flex justify={'flex-end'} mr={10}>
        <a href="./bbai.glm">
          <MenuItem>Reference</MenuItem>
        </a>
        <a href="https://github.com/rnburn/bbai">
          <MenuItem>
              GitHub
          </MenuItem>
        </a>
      </Flex>
    </Flex>
  );
};

export default Header;
